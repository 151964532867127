<template>
	<b-modal
		ref="check-madrillas"
		:title="'Check Madrillas | Huevos sin registrar'"
		no-close-on-backdrop
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="xmd"
		hide-footer
	>
		<div>
			<b-row class="mb-1">
				<b-col class="d-flex align-items-center">
					<small class="text-secondary">
						Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
					</small>
				</b-col>
				<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
					<b-pagination
						v-model="pagination.curPage"
						:total-rows="pagination.total"
						:per-page="pagination.perPage"
						first-number
						last-number
						class="mb-0 mt-1 mt-sm-0"
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
			<b-row class="mb-1">
				<b-col cols="12" xl="9" class="d-flex align-items-center justify-content-start mt-1 mb-md-0">
					<label>Mostrar</label>
					<v-select
						v-model="pagination.perPage"
						:options="perPageOptions"
						:clearable="false"
						class="per-page-selector d-inline-block mx-50"
						style="width: 90px"
					/>
					<label class="mr-2">registros</label>
					<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="refresh" />
				</b-col>

				<b-col cols="12" xl="3" style="margin-top: 1.6rem">
					<div class="container-btns-page-specimens">
						<div class="d-flex">
							<b-form-input
								v-model="search_input"
								class="d-inline-block mr-1"
								placeholder="Placa o alias..."
								@keyup.enter="getMadrillas"
								debounce="500"
							/>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-table-simple responsive sticky-header="60vh" no-border-collapse>
				<b-thead class="sticky-header">
					<b-th class="text-center">Madrilla</b-th>
					<b-th class="text-center">Postura</b-th>
					<b-th class="text-center">Último registro</b-th>
					<b-th class="text-center" style="cursor: pointer" @click="toggleOrderBy">
						<span>Tiempo</span>
						<font-awesome-icon
							icon="fa-solid fa-arrow-up-long"
							size="lg"
							class="text-text ml-1 cursor-pointer"
							:class="{ 'text-primary': orderBy === 'ASC' }"
						/>
						<font-awesome-icon
							icon="fa-solid fa-arrow-down-long"
							size="lg"
							class="text-text cursor-pointer"
							:class="{ 'text-primary': orderBy === 'DESC' }"
						/>
					</b-th>
					<b-th class="text-center">Accion</b-th>
				</b-thead>
				<b-tbody>
					<template v-for="(madrilla, index) in madrillas">
						<b-tr :key="index">
							<b-td class="text-center">
								<SpecimenPlate :specimen="madrilla.madrilla" />
							</b-td>
							<b-td class="text-center">
								{{ madrilla.code }}
							</b-td>
							<b-td class="text-center">
								<span v-if="madrilla.is_activate === 0">
									{{ madrilla.max_date | myGlobalDateTime }}
								</span>
								<span v-else>
									<b-badge variant="danger">sin registro</b-badge>
								</span>
							</b-td>
							<b-td class="text-center">
								<!-- <span v-if="madrilla.diff_hours">{{ convertHoursToDays(madrilla.diff_hours) }}</span> -->
								<span v-if="madrilla.diff_hours">{{ madrilla.diff_hours }} días</span>
								<span v-else>---</span>
							</b-td>
							<b-td class="text-center">
								<FeatherIcon
									@click="openModalFinishedPostura(madrilla)"
									icon="LogOutIcon"
									class="text-success cursor-pointer"
									size="24"
									v-b-tooltip.hover.top="'Finalizar postura'"
								/>
							</b-td>
						</b-tr>
					</template>
					<b-tr v-if="madrillas.length === 0">
						<b-td colspan="10" class="text-center" style="height: 80px">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>

			<b-modal
				v-model="modalFinishedPostura.visible"
				:title="modalFinishedPostura.title"
				body-class="m-0 p-2"
				@ok="finishedPostura"
			>
				<div>
					<b-form-group>
						<v-select
							v-model="status_madrilla"
							:options="arrayReasons"
							:clearable="false"
							transition=""
							label="description"
							placeholder="Seleccionar estado de la madrilla"
							:reduce="(option) => option.id"
						/>
					</b-form-group>
				</div>
			</b-modal>
		</div>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import PostureService from "@/services/posturas.service.js"
import reasonsService from "@/services/reasons.service"
import posturaService from "@/services/posturas.service"
import specimenService from "@/services/specimens.service"
import { mapActions } from "vuex"
import { orderBy } from "lodash"

export default {
	components: { SpecimenPlate },
	mixins: [modalMixin],
	props: {
		show: Boolean,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			madrillas: [],
			perPageOptions: [10, 25, 50, 100],
			pagination: {
				total: 0,
				curPage: 1,
				perPage: 50,
				from: 0,
				to: 0,
			},
			search_input: "",
			arrayReasons: [],
			reason_id: null,
			status_madrilla: null,
			modalFinishedPostura: {
				title: "Finalizar postura",
				visible: false,
				postura_id: null,
			},
			orderBy: "DESC",
		}
	},
	async created() {
		await this.getMadrillas()
		// await this.getReasonsByModule()
		await this.getSpecimenStatusTrackings()
	},
	computed: {},
	methods: {
		...mapActions({
			A_GET_CHECK_MADRILLAS_COUNTER: "commons/A_GET_CHECK_MADRILLAS_COUNTER",
		}),

		async getSpecimenStatusTrackings() {
			this.arrayReasons = await specimenService.getAllStatusSpecimens(2)
			this.arrayReasons = this.arrayReasons.filter((item) => item.in_galpon == 1)
			this.arrayReasons = this.arrayReasons.filter((item) => [15, 12, 9, 6].includes(item.id))
		},

		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		toggleOrderBy() {
			if (this.orderBy === "DESC") {
				this.orderBy = "ASC"
			} else {
				this.orderBy = "DESC"
			}
		},
		async refresh() {
			await this.getMadrillas()
		},
		async getMadrillas() {
			this.isPreloading()
			try {
				const { data } = await PostureService.getMadrillasWithoutEggs({
					search: this.search_input,
					page: this.pagination.curPage,
					perpage: this.pagination.perPage,
					orderBy: this.orderBy,
				})
				this.madrillas = data.data
				this.pagination.total = data.total
				this.pagination.from = data.from
				this.pagination.to = data.to
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
		convertHoursToDays(hours) {
			const days = Math.floor(hours / 24)
			const remainingHours = hours % 24

			if (remainingHours === 0) {
				return `${days} ${days === 1 ? "día" : "días"}`
			}

			return `${days} días y ${remainingHours} horas`
		},
		async getReasonsByModule() {
			const data = await reasonsService.getByModule(2)
			this.arrayReasons = data.map((item) => {
				return {
					id: item.id,
					label: item.reason,
				}
			})
		},
		async openModalFinishedPostura(data) {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `La postura de ${data.madrilla.alias || data.madrilla.plate} se pasará a FINALIZADAS.`,
			})
			if (!isConfirmed) return

			this.modalFinishedPostura.visible = true
			this.modalFinishedPostura.title = `Finalizar postura ${data.code}`
			this.modalFinishedPostura.postura_id = data.postura_id
		},
		async finishedPostura() {
			const resp = await posturaService.finishPostura({
				postura_id: this.modalFinishedPostura.postura_id,
				reason_text: this.reason_id,
				status_madrilla_id: this.status_madrilla,
			})
			await this.refreshTotalPosturas()
			console.log(resp)
			this.showSuccessToast(resp.message)
		},
		async refreshTotalPosturas() {
			await this.A_GET_CHECK_MADRILLAS_COUNTER()
			await this.getMadrillas()
		},
	},
	watch: {
		"pagination.curPage": async function () {
			await this.getMadrillas()
		},
		"pagination.perPage": async function () {
			await this.getMadrillas()
		},
		orderBy: async function () {
			this.pagination.curPage = 1
			await this.getMadrillas()
		},
	},
	async mounted() {
		this.toggleModal("check-madrillas")
	},
}
</script>

<style scoped></style>
